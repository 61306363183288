import * as XLSX from "xlsx";
import bancos from '../../../Assets/bancos.json';

const DownloadExcelFinanceiro = async (bolsistas) => {
  const status = "ativo";
  const filteredData = bolsistas.filter(
    (bolsista) => bolsista.status === status
  );

  const formattedBanks = bancos
    .filter((banco) => banco.fullName && banco.fullName.trim() !== '')
    .sort((a, b) => a.fullName.localeCompare(b.fullName))
    .map((banco) => ({
      value: banco.code,
      label: banco.fullName,
      key: banco.code
    }));

  const modifiedBolsista = await Promise.all(
    filteredData.map(async (bolsista) => ({
      Credor: bolsista.cpf.trim(),
      "Valor do Item": 100,
      "Código do banco do Domicílio Bancário de Destino": (formattedBanks.find(banco => banco.label === bolsista.banco)?.key ?? "").toString(),
      "Código da agência do Domicílio Bancário de Destino": bolsista.agencia,
      "Domicílio Bancário de Destino": bolsista.conta.trim(),
    }))
  );

  const ws = XLSX.utils.json_to_sheet(modifiedBolsista);
  const range = XLSX.utils.decode_range(ws["!ref"]);

  for (let C = range.s.c; C <= range.e.c; ++C) {
    const address = XLSX.utils.encode_col(C) + "1";
    if (ws[address]) {
      ws[address].v = ws[address].v.toUpperCase();
      ws[address].s = { font: { bold: true } };
    }
  }

  // Calcular a largura necessária para cada coluna
  const colWidths = [];
  for (let C = range.s.c; C <= range.e.c; ++C) {
    let maxWidth = 5; // Largura mínima para cada coluna
    for (let R = range.s.r; R <= range.e.r; ++R) {
      const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
      const cell = ws[cellAddress];
      if (cell) {
        cell.s = { alignment: { horizontal: "left" } };
        if (cell.v){
          const cellLength = cell.v.toString().length;
          if (cellLength > maxWidth) {
            maxWidth = cellLength;
            console.log(cell.v.toString());
          }
        }
      }
    }
    colWidths.push({ wch: maxWidth + 3 });
  }

  // Aplicar a largura calculada a cada coluna
  ws["!cols"] = colWidths;

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Bolsistas");
  XLSX.writeFile(wb, "dadosparapagamento.xlsx");
};

export default DownloadExcelFinanceiro;
