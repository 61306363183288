import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUserContext } from "../../../../contexts/UserContext";
import { Notify, ToastType } from "../../../../components/notify/notify";
import EstudanteForm from "./EstudanteForm";

const Estudante = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;
  let isAtivo = !previousData
    ? false
    : previousData.status === "ativo"
    ? true
    : false;
  // if (previousData) {
  //   isAtivo = previousData.status === "ativo";
  // }

  const { user } = useUserContext();
  const [isAllowedToEstudanteForm, setIsAllowedToEstudanteForm] = useState(
    user.profile === "SECRETARIO" ||
      (isEditar && user.profile === "MULTIPROFISSIONAL")
  );

  const [formData, setFormData] = useState({
    banco: "",
    agencia: "",
    conta: "",
    nome: "",
    cpf: "",
    historicoMatriculas: "",
    matricula:"",
    nis: "",
    dataNascimento: "",
  });

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
      // if (formData.status === "ativo") {
      // 06/08 - aluno ativo não pode ser editado
      // 07/08 - agora decidiram que pode sim
      // setIsAllowedToEstudanteForm(false);
      // }
    }

    if (!isAllowedToEstudanteForm) {
      Notify({
        message: `Você não tem autorização para essa página!`,
        type: ToastType.Error,
      });
      navigate(-1);
    }
  }, [
    previousData,
    navigate,
    isAllowedToEstudanteForm,
    setIsAllowedToEstudanteForm,
    formData.status,
  ]);

  // Às vezes serieId vem null mas serieDescricao não
  const determineSerieIdFromDescription = (description) => {
    const firstChar = description ? description.charAt(0) : null;
    switch (firstChar) {
      case "1":
        return 1;
      case "2":
        return 2;
      case "3":
        return 3;
      case "9":
        return 9;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEditar && formData.serieId === null && formData.serieDescricao) {
      const derivedSerieId = determineSerieIdFromDescription(
        formData.serieDescricao
      );
      setFormData((prevState) => ({
        ...prevState,
        serieId: derivedSerieId,
      }));
    }
  }, [isEditar, formData.serieId, formData.serieDescricao]);

  return (
    user && (
      <div>
        {isAllowedToEstudanteForm && (
          <EstudanteForm
            formData={formData}
            setFormData={setFormData}
            isEditar={isEditar}
            isAtivo={isAtivo}
          />
        )}
      </div>
    )
  );
};

export default Estudante;
