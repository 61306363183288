import React from 'react';
import styled from "styled-components";

const DivFooter = styled.div`
  background-color: #E2E5E5;
  color: #000000;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

function Footer() {
  return (
    <footer>
      <DivFooter>
        <p>Desenvolvido pela Gerência de Sistemas da Seduc Tocantins - Todos os direitos reservados &copy; {new Date().getFullYear()}</p>
      </DivFooter>
    </footer>
  );
}

export default Footer;
