import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import StatusField from "../../components/informationsField/StatusField";
import ModalInfo from "../../components/modal/ModalInfoEstudante";
import styled from "styled-components";
import { formatCPF, formatDate } from "../../utils/format";
import { useDataContext } from "../../contexts/DataContext";
import Tabela from "../../components/table/Table";
import {fetchBolsistasByPeriod} from "../../api/sgb-api";
import { Notify, ToastType } from "../../components/notify/notify";
import {
  IconContainerTable,
  NoDataMessage,
  StyledDeleteIcon,
  StyledEditIcon,
  StyledSearchIcon,
} from "../../utils/StyledUtils";
import { useUserContext } from "../../contexts/UserContext";
import Modal from "../../components/modal/ModalGenerico";
import Loader from "../../components/loader/Loader";

const Estudantes = ({ escolaId, status }) => {
  let dataAtual = new Date();
  let mesAtualString = (dataAtual.getMonth() + 1).toString();
  let anoAtualString = dataAtual.getFullYear().toString();
  const navigate = useNavigate();
  const { user, token } = useUserContext();
  const { mutationDeleteBolsista, pagamentos } = useDataContext();
  const [bolsistasFiltrado, setBolsistasFiltrado] = useState([]);
  const [mes, setMes] = useState(mesAtualString);
  const [ano, setAno] = useState(anoAtualString);
  const [bolsistas, setBolsistas]= useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pagamento, setPagamento] = useState(null);
  const [currentEstudante, setCurrentEstudante] = useState(null);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasAdd = user.profile === "SECRETARIO";

  const StyledSelect = styled.select`
  padding: 10px;
  font-size: 1em;
  border-radius: 15px;
  border: 1px solid #0062bc;
  background-color: #f3f3f3;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  color: rgb(0, 0, 0);
  font-weight: light;
  cursor: pointer;
  width: 214px;
  height: 44px;
  margin: 0px;
  &:focus {
    outline: none;
    border-color: #004b8d;
  }
`;

  const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
`;

  const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  `;

  const Label = styled.label`
  font-size: 1.2em;
  font-weight: bold;
`;

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const data = await fetchBolsistasByPeriod(token, parseInt(mes, 10), parseInt(ano, 10));
        setBolsistas(data);
      } catch (error) {
        Notify({
          message: `Erro ao buscar bolsistas por período: ${error.message}`,
          type: ToastType.Error,
        });
        console.error("Erro ao buscar bolsistas por período:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [mes,ano])

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const filterPagamento = (estudante) => {
    const pagamentosFiltrados = pagamentos.filter(
      (pagamento) => pagamento.estudante.id === estudante.id
    );
    setPagamento(pagamentosFiltrados);
  };

  const handleViewEstudante = useCallback(
    (id) => {
      let estudante = bolsistas.find((b) => b.id === id);
  
      if (estudante && Array.isArray(estudante.historicoMatriculas) && estudante.historicoMatriculas.length > 0) {
        const maisRecente = estudante.historicoMatriculas.reduce((maisNova, matricula) =>
          new Date(matricula.dataUltimaAtualizacao) > new Date(maisNova.dataUltimaAtualizacao) ? matricula : maisNova
        );
  
        // Criamos um novo objeto para evitar mutação
        const estudanteAtualizado = { ...estudante, historicoMatriculas: maisRecente };
  
        filterPagamento(estudanteAtualizado);
        setCurrentEstudante(estudanteAtualizado);
        setModalIsOpen(true);
      } else {
        console.error("Estudante sem histórico de matrículas válido", estudante);
      }
    },
    [bolsistas]
  );
  const handleEditEstudante = useCallback(
    (id) => {
      const estudante = bolsistas.find((b) => b.id === id);
      navigate("cadastro?editar=true", { state: { previousData: estudante } });
      // if (estudante.status === "ativo") {
      //   Notify({
      //     message: `Aluno ativo não pode ser editado!`,
      //     type: ToastType.Error,
      //   });
      // } else {
      //   navigate("cadastro?editar=true", {
      //     state: { previousData: estudante },
      //   });
      // }
    },
    [bolsistas, navigate]
  );

  const getCurrentUserName = () => {
    if (currentEstudante) {
      return currentEstudante.nome;
    } else {
      return "";
    }
  };

  const openModalDelete = (bolsista) => {
    setCurrentEstudante(bolsista);
    setModalDeleteIsOpen(true);
  };

  const closeModalDelete = () => {
    setModalDeleteIsOpen(false);
  };

  const confirmDeleteAction = () => {
    handleDeleteBolsista(currentEstudante.id);
    closeModalDelete();
  };

  const handleDeleteBolsista = async (id) => {
    try {
      await mutationDeleteBolsista.mutateAsync(id);
    } catch (error) {
      console.error("Erro ao excluir perfil:", error);
    } finally {
      closeModal();
    }
  };


  useEffect(() => {
    let processedBolsistas = bolsistas;
    if (bolsistas.length > 0) {
      setLoading(true);
      

      if (escolaId) {
        processedBolsistas = processedBolsistas.filter(
          (b) => {
            const maisRecente = b.historicoMatriculas.reduce((maisNova, matricula) => {
              return new Date(matricula.dataUltimaAtualizacao) > new Date(maisNova.dataUltimaAtualizacao) ? matricula : maisNova;
            });

            return maisRecente.escola && maisRecente.escola.id.toString() === escolaId
          }
        );
      }

      if (status) {
        if (status !== "ehNovoEstudante") {
          processedBolsistas = processedBolsistas.filter((b) => {
            const maisRecente = b.historicoMatriculas.reduce((maisNova, matricula) => {
              return new Date(matricula.dataUltimaAtualizacao) > new Date(maisNova.dataUltimaAtualizacao) ? matricula : maisNova;
            });
            if (status === "ativo") {
              return maisRecente.status === status && !b.ehNovoEstudante;
            } else {
              return maisRecente.status === status;
            }
          });
        } else {
          processedBolsistas = processedBolsistas.filter(
            (b) =>{
              const maisRecente = b.historicoMatriculas.reduce((maisNova, matricula) => {
                return new Date(matricula.dataUltimaAtualizacao) > new Date(maisNova.dataUltimaAtualizacao) ? matricula : maisNova;
              });
              return (maisRecente.status === "ativo") & b.ehNovoEstudante
            }   
          );
        }
      }

      processedBolsistas = processedBolsistas.map(bolsista => {
        const maisRecente = bolsista.historicoMatriculas.reduce((maisNova, matricula) => 
          new Date(matricula.dataUltimaAtualizacao) > new Date(maisNova.dataUltimaAtualizacao) ? matricula : maisNova
        );

        return {
          nome: bolsista.nome,
          cpf: formatCPF(bolsista.cpf),
          status: <StatusField status={maisRecente.status} upperCase={true} />,
          dataCadastro: formatDate(bolsista.historicoMatriculas?.[0]?.dataUltimaAtualizacao),
          actions: [
            <IconContainerTable>
              <StyledSearchIcon
                size={25}
                onClick={() => handleViewEstudante(bolsista.id)}
              />
              {(user.profile === "MULTIPROFISSIONAL" ||
                user.profile === "SECRETARIO") && (
                  <StyledEditIcon
                    size={25}
                    onClick={() => handleEditEstudante(bolsista.id)}
                  />
                )}
              {(user.profile === "ADMIN") && (
                <StyledDeleteIcon
                  size={25}
                  onClick={() => openModalDelete(bolsista)}
                />
              )}
            </IconContainerTable>,
          ],
        }
      });

      setBolsistasFiltrado(processedBolsistas);
      setLoading(false);
    }else{
      setBolsistasFiltrado(processedBolsistas);
    }
    
  }, [
    bolsistas,
    status,
    escolaId,
    handleViewEstudante,
    user.profile,
    handleEditEstudante,
  ]);

  if (loading) {
    return <Loader />
  }

  const header = ["NOME", "CPF", "STATUS", "CADASTRO/ÚLTIMA ATUALIZAÇÃO", "AÇÕES"];
  const search = ["nome", "cpf", "status"];

  return (
    <div>
      <FilterContainer>
      <SelectContainer>
        <Label>Selecione o Mês:</Label>
        <StyledSelect
          value={mes}
          onChange={(event) => setMes(event.target.value)}
        >
          <option value="1">Janeiro</option>
          <option value="2">Fevereiro</option>
          <option value="3">Março</option>
          <option value="4">Abril</option>
          <option value="5">Maio</option>
          <option value="6">Junho</option>
          <option value="7">Julho</option>
          <option value="8">Agosto</option>
          <option value="9">Setembro</option>
          <option value="10">Outubro</option>
          <option value="11">Novembro</option>
          <option value="12">Dezembro</option>
        </StyledSelect>
      </SelectContainer><SelectContainer>
          <Label>Selecione o Ano:</Label>
          <StyledSelect
            value={ano}
            onChange={(event) => setAno(event.target.value)}
          >
            <option value="2025">2025</option>
            <option value="2024">2024</option>
          </StyledSelect>
        </SelectContainer>
      </FilterContainer>
      {bolsistasFiltrado.length !== 0 ? (
        <Tabela
          data={bolsistasFiltrado}
          header={header}
          paginated={true}
          search={search}
          actionAdd={hasAdd}
        />
      ) : (
        <NoDataMessage>Nenhum dado disponível.</NoDataMessage>
      )}
      <ModalInfo
        isOpen={modalIsOpen}
        onCancel={closeModal}
        data={currentEstudante}
        pagamentos={pagamento}
      />
      <Modal
        isOpen={modalDeleteIsOpen}
        text={`Deseja mesmo deletar ${getCurrentUserName()}?`}
        onProceed={confirmDeleteAction}
        onCancel={closeModalDelete}
        textoOk="Deletar"
        textoCancelar="Cancelar"
      />
    </div>
  );
};

export default Estudantes;
