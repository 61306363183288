import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUserContext } from "../../../../contexts/UserContext";
import EscolaForm from "./EscolaForm";

const selectOptions = [
  { id: 0, value: "ARAGUAINA", label: "Araguaína" },
  { id: 1, value: "ARAGUATINS", label: "Araguatins" },
  { id: 2, value: "ARRAIAS", label: "Arraias" },
  { id: 3, value: "COLINAS", label: "Colinas do Tocantins" },
  { id: 4, value: "DIANOPOLIS", label: "Dianópolis" },
  { id: 5, value: "GUARAI", label: "Guaraí" },
  { id: 6, value: "GURUPI", label: "Gurupi" },
  { id: 7, value: "MIRACEMA", label: "Miracema do Tocantins" },
  { id: 8, value: "PALMAS", label: "Palmas" },
  { id: 9, value: "PARAISO", label: "Paraíso do Tocantins" },
  { id: 10, value: "PEDROAFONSO", label: "Pedro Afonso" },
  { id: 11, value: "PORTONACIONAL", label: "Porto Nacional" },
  { id: 12, value: "TOCANTINOPOLIS", label: "Tocantinópolis" },
];

const Escola = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const location = useLocation();
  const previousData = location.state?.previousData || null;
  const isEditar = previousData !== null;
  const isAllowedToEscolaForm = user.profile === "ADMIN";

  const [formData, setFormData] = useState({
    inep: "",
    efi: false,
    efii: false,
    em: false,
    name: "",
    municipio: "",
    regional: "",
  });

  useEffect(() => {
    if (previousData) {
      setFormData(previousData);
    }
    if (!isAllowedToEscolaForm){
      navigate(-1);
    }
  }, [previousData, isAllowedToEscolaForm, navigate]);

  return (
    user && (
      <div>
        {isAllowedToEscolaForm && (
          <EscolaForm
            formData={formData}
            setFormData={setFormData}
            isEditar={isEditar}
            selectOptions={selectOptions}
          />
        )}
      </div>
    )
  );
};

export default Escola;
