import React, { useState, useEffect, useCallback } from "react";
import { Notify, ToastType } from "../../components/notify/notify";
import { Chart } from "react-google-charts";
import styled from "styled-components";
import { useUserContext } from "../../contexts/UserContext";
import { Tabs, Tab } from "../../components/modal/StyledModal";
import { useDataContext } from "../../contexts/DataContext";
import {fetchBolsistasByPeriod} from "../../api/sgb-api";
import Loader from "../../components/loader/Loader";

const GraficosWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Ajuste para melhor aproveitamento */
  gap: 30px; /* Aumenta o espaçamento entre os gráficos */
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* No mobile, mantém empilhado */
    gap: 20px;
  }
`;

const Grafico = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  /* Remova o overflow para não forçar barra de rolagem: */
  /* overflow-x: hidden; */
  /* Se precisar garantir que não haja rolagem interna: */
  overflow: visible;
`;

const ResponsiveTabs = styled(Tabs)`
  display: flex;
  flex-wrap: wrap; /* Permite quebrar linha no mobile */
  justify-content: center;
  gap: 10px;
`;

  const StyledSelect = styled.select`
  padding: 10px;
  font-size: 1em;
  border-radius: 15px;
  border: 1px solid #0062bc;
  background-color: #f3f3f3;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.15);
  color: rgb(0, 0, 0);
  font-weight: light;
  cursor: pointer;
  width: 214px;
  height: 44px;
  margin: 0px;
  &:focus {
    outline: none;
    border-color: #004b8d;
  }
`;

  const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
`;

  const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  gap: 10px; /* Espaçamento entre os elementos */
`;

const CurrentFilter = styled.div`
  font-size: 1em;
  font-weight: bold;
  color: #333;
  margin-left: 20px;
`;

const Label = styled.label`
font-size: 1.2em;
font-weight: bold;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin-top: 1em;
  font-size: 1em;
  border-radius: 15px;
  border: none;
  background-color: #0062bc;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => (props.disabled ? '#0062bc' : '#004b8d')};
  }

  &:focus {
    outline: none;
  }

  /* Estilos quando o botão está desativado */
  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const Dashboard = () => {
  let dataAtual = new Date();
  let mesAtualString = (dataAtual.getMonth() + 1).toString();
  let anoAtualString = dataAtual.getFullYear().toString();
  const { user, token } = useUserContext();
  const { bolsistas, escolas } = useDataContext();
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [mes, setMes] = useState(mesAtualString);
  const [ano, setAno] = useState(anoAtualString);
  const [mesSelecionado, setMesSelecionado] = useState(mesAtualString);
  const [anoSelecionado, setAnoSelecionado] = useState(anoAtualString);

  const backgroundColor = "transparent";
  let nomeRegional;

  if (user.profile !== "MULTIPROFISSIONAL") {
    switch (user.regional) {
      case "ARAGUAINA":
        nomeRegional = "Araguaína";
        break;
      case "ARAGUATINS":
        nomeRegional = "Araguatins";
        break;
      case "ARRAIAS":
        nomeRegional = "Arraias";
        break;
      case "COLINAS":
        nomeRegional = "Colinas do Tocantins";
        break;
      case "DIANOPOLIS":
        nomeRegional = "Dianópolis";
        break;
      case "GUARAI":
        nomeRegional = "Guaraí";
        break;
      case "GURUPI":
        nomeRegional = "Gurupi";
        break;
      case "MIRACEMA":
        nomeRegional = "Miracema do Tocantins";
        break;
      case "PALMAS":
        nomeRegional = "Palmas";
        break;
      case "PARAISO":
        nomeRegional = "Paraíso do Tocantins";
        break;
      case "PEDROAFONSO":
        nomeRegional = "Pedro Afonso";
        break;
      case "PORTONACIONAL":
        nomeRegional = "Porto Nacional";
        break;
      case "TOCANTINOPOLIS":
        nomeRegional = "Tocantinópolis";
        break;
      default:
        nomeRegional = "Regional não encontrada";
    }
  }

  // Ajuste dos gráficos para 100% da largura do container
  const chartWidth = "100%";
  const chartHeight = "300px"; // altura razoável para mobile, pode ajustar

  const options = {
    title: "Estudantes por Status",
    pieHole: 0.4,
    slices: [
      { color: "green" },
      { color: "#20B2AA" },
      { color: "red" },
      { color: "orange" },
    ],
    titleTextStyle: {
      fontSize: 18,
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options2 = {
    title: "Estudantes por Regional",
    pieHole: 0.4,
    titleTextStyle: {
      fontSize: 18,
    },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options3 = {
    title: "Escolas por Regional",
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options4 = {
    title: "Estudantes cadastrados por Escola Total - Regional " + nomeRegional,
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const options5 = {
    title: "Estudantes por Série e Ano",
    pieHole: 0.4,
    titleTextStyle: { fontSize: 18 },
    sliceVisibilityThreshold: 0,
    legend: {
      position: "right",
      maxLines: 2,
      textStyle: { fontSize: 12 },
    },
    chartArea: { width: "90%", height: "80%" },
    backgroundColor,
  };

  const [isFirstAccessTab2, setIsFirstAccessTab2] = useState(true);

  const [totalIndeferido, setTotalIndeferido] = useState(0);
  const [totalPendente, setTotalPendente] = useState(0);
  const [totalnovosAtivos, setTotalNovosAtivos] = useState(0);
  const [totalAtivos, setTotalAtivos] = useState(0);
  const [totalinativo, setTotalInativo] = useState(0);
  const [totalNono, setTotalNono] = useState(0);
  const [totalPrimo, setTotalPrimo] = useState(0);
  const [totalSegun, setTotalSegun] = useState(0);
  const [totalTerce, setTotalTerce] = useState(0);

  const [bolsistasFilter, setBolsistasFilter] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
        setLoading(true); // Define isLoadingA como true
        //console.log("isLoadingA definido como true"); // Verifique se esta linha é executada
        try {
          if (ano !== "0" && isFirstAccessTab2) {
            const data = await fetchBolsistasByPeriod(token, parseInt(mes, 10), parseInt(ano, 10));
            setBolsistasFilter(data);
            //Carreegamento dos status
            //console.log(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "pendente").length);
            setTotalPendente(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "pendente").length); // Atualiza o total de pendentes com o tamanho dos dados
            setTotalIndeferido(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "indeferido").length); // Atualiza o total de indeferidos com o tamanho dos dados
            setTotalNovosAtivos(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "ativo" && bolsista.ehNovoEstudante === true).length); // Atualiza o total de novos ativos com o tamanho dos dados
            setTotalAtivos(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "ativo" && bolsista.ehNovoEstudante === false).length); // Atualiza o total de ativos com o tamanho dos dados
            //Estudantes Por Série e Ano
            setTotalInativo(data.filter((bolsista) => bolsista.historicoMatriculas[0].status === "inativo").length); // Atualiza o total de inativos com o tamanho dos dados
            setTotalNono(data.filter((bolsista) => bolsista.historicoMatriculas[0].serieDescricao === "9º ANO").length); // Atualiza o total de 9º ano com o tamanho dos dados
            setTotalPrimo(data.filter((bolsista) => bolsista.historicoMatriculas[0].serieDescricao === "1ª SÉRIE").length); // Atualiza o total de 1ª série com o tamanho dos dados
            setTotalSegun(data.filter((bolsista) => bolsista.historicoMatriculas[0].serieDescricao === "2ª SÉRIE").length); // Atualiza o total de 2ª série com o tamanho dos dados
            setTotalTerce(data.filter((bolsista) => bolsista.historicoMatriculas[0].serieDescricao === "3ª SÉRIE").length); // Atualiza o total de 3ª série com o tamanho dos dados
  
            //Estudantes por Regional

            setIsFirstAccessTab2(false);
  
          } else {
            //Carreegamento dos status
            const pendentes = bolsistas.filter((bolsista) => bolsista.status === "pendente").length;
            const indeferidos = bolsistas.filter((bolsista) => bolsista.status === "indeferido").length;
            const novosAtivos = bolsistas.filter((bolsista) => bolsista.status === "ativo" && bolsista.ehNovoEstudante === true).length;
            const ativos = bolsistas.filter((bolsista) => bolsista.status === "ativo" && bolsista.ehNovoEstudante === false).length;
            setTotalPendente(pendentes); // Atualiza o total de pendentes com base na lista de bolsistas
            setTotalIndeferido(indeferidos); // Atualiza o total de indeferidos com base na lista de bolsistas
            setTotalNovosAtivos(novosAtivos); // Atualiza o total de novos ativos com base na lista de bolsistas
            setTotalAtivos(ativos); // Atualiza o total de ativos com base na lista de bolsistas
            //Estudantes Por Série e Ano
            const inativos = bolsistas.filter((bolsista) => bolsista.status === "inativo").length;
            const nono = bolsistas.filter((bolsista) => bolsista.serieDescricao === "9º ANO").length;
            const primo = bolsistas.filter((bolsista) => bolsista.serieDescricao === "1ª SÉRIE").length;
            const segun = bolsistas.filter((bolsista) => bolsista.serieDescricao === "2ª SÉRIE").length;
            const terce = bolsistas.filter((bolsista) => bolsista.serieDescricao === "3ª SÉRIE").length;
            setTotalInativo(inativos); // Atualiza o total de inativos com base na lista de bolsistas
            setTotalNono(nono); // Atualiza o total de 9º ano com base na lista de bolsistas
            setTotalPrimo(primo); // Atualiza o total de 1ª série com base na lista de bolsistas
            setTotalSegun(segun); // Atualiza o total de 2ª série com base na lista de bolsistas
            setTotalTerce(terce); // Atualiza o total de 3ª série com base na lista de bolsistas
          }
        } catch (error) {
          Notify({
            message: `Erro ao buscar bolsistas por período: ${error.message}`,
            type: ToastType.Error,
          });
          console.error("Erro ao buscar bolsistas por período:", error);
        } finally {
          setLoading(false); // Define isLoadingA como false após a conclusão
          //console.log("isLoadingA definido como false"); // Verifique se esta linha é executada
        }
      };
      fetchData();
  }, [mes, ano, activeTab]); // Remova outras dependências, mantendo apenas activeTab


  const dadosStatus = [
    ["Status", "Quantidade"],
    [`Homologados (${totalAtivos})`, totalAtivos],
    [`Novos Homologados  (${totalnovosAtivos})`, totalnovosAtivos],
    [`Indeferidos (${totalIndeferido})`, totalIndeferido],
    [`Cadastrados (${totalPendente})`, totalPendente],
    [`Inativos (${totalinativo})`, totalinativo],
  ];

  const dadosSerie = [
    ["Série/Ano", "Quantidade"],
    [`9° Ano - EF (${totalNono})`, totalNono],
    [`1° Série - EM (${totalPrimo})`, totalPrimo],
    [`2° Série - EM (${totalSegun})`, totalSegun],
    [`3° Série - EM (${totalTerce})`, totalTerce],
  ];

  const EstudantesPorRegional = (regional) =>
    {
      if (ano !== "0") {
        //console.log(bolsistasFilter)
        return bolsistasFilter.filter((b) => b.historicoMatriculas[0].escola?.regional === regional).length;
      }
      else {
        return bolsistas.filter((b) => b.escola?.regional === regional).length;
      }
    }


  const estudantePorRegional = [
    ["Regional", "Total De Estudante"],
    ["Araguaína", EstudantesPorRegional("ARAGUAINA") || 0],
    ["Araguatins", EstudantesPorRegional("ARAGUATINS") || 0],
    ["Arraias", EstudantesPorRegional("ARRAIAS") || 0],
    ["Colinas do Tocantins", EstudantesPorRegional("COLINAS") || 0],
    ["Dianópolis", EstudantesPorRegional("DIANOPOLIS") || 0],
    ["Guaraí", EstudantesPorRegional("GUARAI") || 0],
    ["Gurupi", EstudantesPorRegional("GURUPI") || 0],
    ["Miracena do Tocantins", EstudantesPorRegional("MIRACEMA") || 0],
    ["Palmas", EstudantesPorRegional("PALMAS") || 0],
    ["Paraíso do Tocantins", EstudantesPorRegional("PARAISO") || 0],
    ["Pedro Afonso", EstudantesPorRegional("PEDROAFONSO") || 0],
    ["Porto Nacional", EstudantesPorRegional("PORTONACIONAL") || 0],
    ["Tocantinópolis", EstudantesPorRegional("TOCANTINOPOLIS") || 0],
  ];

  let escolaPorRegional = [];
  let escolasDaRegional = [];

  if (escolas !== "UNAUTHORIZED") {
    escolaPorRegional = [
      ["Regional", "Total De Escolas"],
      ["Araguaína", escolas.filter((item) => item.regional === "ARAGUAINA").length],
      ["Araguatins", escolas.filter((item) => item.regional === "ARAGUATINS").length],
      ["Arraias", escolas.filter((item) => item.regional === "ARRAIAS").length],
      ["Colinas do Tocantins", escolas.filter((item) => item.regional === "COLINAS").length],
      ["Dianópolis", escolas.filter((item) => item.regional === "DIANOPOLIS").length],
      ["Guaraí", escolas.filter((item) => item.regional === "GUARAI").length],
      ["Gurupi", escolas.filter((item) => item.regional === "GURUPI").length],
      ["Miracena do Tocantins", escolas.filter((item) => item.regional === "MIRACEMA").length],
      ["Palmas", escolas.filter((item) => item.regional === "PALMAS").length],
      ["Paraíso do Tocantins", escolas.filter((item) => item.regional === "PARAISO").length],
      ["Pedro Afonso", escolas.filter((item) => item.regional === "PEDROAFONSO").length],
      ["Porto Nacional", escolas.filter((item) => item.regional === "PORTONACIONAL").length],
      ["Tocantinópolis", escolas.filter((item) => item.regional === "TOCANTINOPOLIS").length],
    ];

    escolasDaRegional = [
      ["Escola", "Total de Alunos"],
      ...escolas.map((escola) => [escola.name, escola.totalBolsistas]),
    ];
  }



  if (isLoading) {
    return <Loader />;
  }

  const handleFilterClick = () => {
    setIsFirstAccessTab2(true);
    setMes(mesSelecionado);
    setAno(anoSelecionado);
    // Aqui você pode adicionar lógica adicional, como buscar dados ou atualizar gráficos
  };

  const isButtonDisabled = mesSelecionado === mes && anoSelecionado === ano;

  const monthNames = [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
    "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
  ];

  return (
    <>
      {user.profile !== "MULTIPROFISSIONAL" && (
        <Section>
          <SectionTitle>Escolas</SectionTitle>
          <GraficosWrapper>
            <Grafico>
              <Chart
                width={chartWidth}
                height={chartHeight}
                chartType="PieChart"
                data={escolaPorRegional}
                options={options3}
              />
            </Grafico>
          </GraficosWrapper>
        </Section>
      )}

      <Section>
        <SectionTitle>Estudantes</SectionTitle>
        <div>
          <FilterContainer>
            <SelectContainer>
              <Label>Selecione o Mês:</Label>
              <StyledSelect
                value={mesSelecionado}
                onChange={(event) => setMesSelecionado(event.target.value)}
              >
                <option value="1">Janeiro</option>
                <option value="2">Fevereiro</option>
                <option value="3">Março</option>
                <option value="4">Abril</option>
                <option value="5">Maio</option>
                <option value="6">Junho</option>
                <option value="7">Julho</option>
                <option value="8">Agosto</option>
                <option value="9">Setembro</option>
                <option value="10">Outubro</option>
                <option value="11">Novembro</option>
                <option value="12">Dezembro</option>
              </StyledSelect>
            </SelectContainer>
            <SelectContainer>
              <Label>Selecione o Ano:</Label>
              <StyledSelect
                value={anoSelecionado}
                onChange={(event) => setAnoSelecionado(event.target.value)}
              >
                <option value="2025">2025</option>
                <option value="2024">2024</option>
              </StyledSelect>
            </SelectContainer>
            <Button onClick={handleFilterClick} disabled={isButtonDisabled}>
              Aplicar Filtros
            </Button>
          </FilterContainer>
          <FilterContainer>
            <Label>Filtro Atual: {`${monthNames[mes - 1]} - ${ano}`}</Label>
          </FilterContainer>
        </div>
        <GraficosWrapper>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={dadosStatus}
              options={options}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={dadosSerie}
              options={options5}
            />
          </Grafico>
          <Grafico>
            <Chart
              width={chartWidth}
              height={chartHeight}
              chartType="PieChart"
              data={estudantePorRegional}
              options={options2}
            />
          </Grafico>
        </GraficosWrapper>
      </Section>
    </>
  );
};

export default Dashboard;
