import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import Sidebar from "../menu/sidebar/Sidebar";
import ProfileBar from "../menu/profileBar/ProfileBar";
import Breadcrumb from "../menu/breadcrumb/Breadcrumb";
import Modal from "../modal/Modal";

const BREAKPOINT = 992; // Largura onde a sidebar vira hamburger

const GridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "content content";
  grid-template-columns: 1fr;
  grid-template-rows: 50px auto;
  transition: grid-template-columns 0.3s;
`;

const RightContainer = styled.div`
  margin: 0px 40px;
`;

const HeaderContainer = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 80px;
  background: transparent;
`;

const ContentContainer = styled.div`
  grid-area: content;
  padding: 0px 0;
`;

const FloatingHamburgerButton = styled.button`
  position: fixed;
  top: 20px;
  left: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04294b;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  z-index: 9999;

  &:hover {
    color: #043c70;
  }
`;

const LayoutSidebar = () => {
  const [expanded, setExpanded] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < BREAKPOINT);
  const [modalOpen, setModalOpen] = useState(false);

  const handleToggle = useCallback(
    (value) => {
      setExpanded(value !== undefined ? value : !expanded);
    },
    [expanded]
  );

  const handleResize = useCallback(() => {
    const newIsMobile = window.innerWidth < BREAKPOINT;
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      {/* Se não for mobile, usa o grid com sidebar normal */}
      {!isMobile && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `${expanded ? "240px" : "80px"} 1fr`,
            gridTemplateRows: "50px auto",
            gridTemplateAreas: '"sidebar header" "sidebar content"',
          }}
        >
          <div style={{ gridArea: "sidebar" }}>
            <Sidebar
              expanded={expanded}
              onToggle={handleToggle}
              isMobileView={false}
            />
          </div>
          <div style={{ gridArea: "header" }}>
            <HeaderContainer>
              <Breadcrumb />
              <ProfileBar />
            </HeaderContainer>
          </div>
          <div style={{ gridArea: "content", margin: "20px 40px" }}>
            <Outlet />
          </div>
        </div>
      )}

      {/* Se for mobile, não mostra sidebar, mostra apenas o header com hamburger */}
      {isMobile && (
        <GridContainer style={{ paddingTop: "20px" }}>
          <HeaderContainer>
            <FloatingHamburgerButton onClick={openModal}>
              <FaBars />
            </FloatingHamburgerButton>
            {/* <ProfileBar /> */}
          </HeaderContainer>
          <RightContainer>
            <ContentContainer>
              {/* <Breadcrumb /> */}
              <Outlet />
            </ContentContainer>
          </RightContainer>
        </GridContainer>
      )}

      {/* Modal para quando for mobile */}
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <Sidebar
          expanded={true}
          onToggle={() => {}}
          isMobileView={true}
          onCloseModal={closeModal}
        />
      </Modal>
    </>
  );
};

export default LayoutSidebar;
