import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { FaEye, FaEyeSlash } from "react-icons/fa";


// assets
import loginImage from "./Assets/Profe_rapaz.png";
import seduclogo from "./Assets/sgb.png";
import sgblogo from "./Assets/sgbprofe.png";

// components
import CustomButton from "./components/button/Button";
import Footer from './components/footer/Footer';
import { ToastType, Notify } from "../src/components/notify/notify";

import { useUserContext } from "../src/contexts/UserContext";
import ModalRecuperarSenha from "./ModalRecuperarSenha";
import "./styles.css";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setUser, setToken } = useUserContext();
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let sanitizedValue = DOMPurify.sanitize(value);
    setFormData({
      ...formData,
      [name]: sanitizedValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        setUser(data.perfil);
        setToken(token);

        navigate("/home");
      } else {
        console.error("Erro ao fazer login:", response.statusText);
        Notify({
          message: `Login falhou. Verifique seu nome de usuário e senha.`,
          type: ToastType.Error,
        });
      }
    } catch (error) {
      console.error("Erro inesperado:", error);
      Notify({
        message: `Ocorreu um erro inesperado durante o login. ${error}`,
        type: ToastType.Error,
      });
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="login-container">
        <div className="login-image">
          <img src={loginImage} alt="Imagem de Login" />
        </div>
        <div className="login-form">
          <img src={seduclogo} alt="Imagem de seduc" />
          <h2>SGB - LOGIN</h2>
          <form onSubmit={handleSubmit}>
            <label>
              <span style={{ paddingRight: "70%" }}>E-mail:</span>
              <br />
              <input
                type="text"
                name="login"
                placeholder="@seduc.to.gov.br"
                value={formData.login}
                onChange={handleChange}
                autoComplete="username"
              />
            </label>
            <br />
            <label className="password">
              <span style={{ paddingRight: "100%" }}>Senha:</span>
              <br />
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="current-password"
              />

              <button
                type="button"
                className="eye-button"
                onClick={() => setShowPassword(!showPassword)}
                aria-label="Toggle password visibility"
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </label>
            <br />
            <CustomButton style={{ marginBottom: "3px" }} type="submit">
              Entrar
            </CustomButton>
          </form>
          <p>
            Esqueceu a senha?
            <Link className="link-form" onClick={openModal}>
              {" "}
              Recuperar Senha
            </Link>
          </p>
          <img src={sgblogo} alt="img sgb" />
        </div>
        {isModalOpen && <ModalRecuperarSenha closeModal={closeModal} />}
      </div>
      <Footer />
    </>
  );
};

export default Login;
