import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

import { FaSearch } from "react-icons/fa";
import {
  Input,
  Label,
  FormGroup,
  ButtonWrapper,
  LabelCheckbox,
} from "../StyledForm";

import { useUserContext } from "../../../../contexts/UserContext";
import { Notify, ToastType } from "../../../../components/notify/notify";
import Container from "../../../../components/container/Container";
import CustomButton from "../../../../components/button/Button";
import { useDataContext } from "../../../../contexts/DataContext";

const EscolaForm = ({
  formData,
  setFormData,
  selectOptions,
  isEditar = false,
}) => {
  const { token } = useUserContext();
  const { mutationPostEscola, mutationPutEscola } = useDataContext();
  const navigate = useNavigate();

  const tituloForm = isEditar ? `Editar` : `Cadastrar`;
  const [formInep, setFormInep] = useState({ inep: "" });

  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(isEditar ? true : false);

  const handleChangeInep = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value.replace(/\D/g, ""));
    setFormInep({ ...formInep, [name]: sanitizedValue });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value.replace(/\D/g, ""));
    setFormData({ ...formData, [name]: sanitizedValue });
  };

  const handleChangeSelect = (e) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      regional: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: checked }));
  };

  const handleCancel = () => {
    navigate("/escolas");
  };

  const buscarAPI = async (inep) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/escola/searchInep/${inep}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        Notify({
          message: `Erro ao buscar escola na API. ${response.statusText}`,
          type: ToastType.Error,
        });
        throw new Error("Erro ao buscar escola na API");
      }

      const responseData = await response.text();
      return JSON.parse(responseData);
    } catch (error) {
      Notify({
        message: `Erro ao buscar escola na API. ${error.message}`,
        type: ToastType.Error,
      });
      console.error("Erro ao buscar escola na API", error);
      return null;
    }
  };

  const handleSubmitInep = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const escolaData = await buscarAPI(Number(formInep.inep));
      if (escolaData.name) {
        setDataLoaded(true);
        setFormData((prevData) => ({
          ...prevData,
          name: escolaData.name,
          municipio: escolaData.municipio,
          regional: escolaData.regional,
        }));
        Notify({ message: `Escola encontrada`, type: ToastType.Success });
      } else {
        Notify({ message: `Escola não encontrada`, type: ToastType.Warning });
      }
    } catch (error) {
      Notify({
        message: `Erro ao buscar escola! Tente mais tarde.`,
        type: ToastType.Error,
      });
      console.error("Erro ao buscar escola", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.inep = Number(formInep.inep);
    if (isEditar) {
      try {
        await mutationPutEscola.mutateAsync(formData);
      } catch (error) {
        console.error("Erro ao editar escola:", error);
      }
    } else {
      try {
        await mutationPostEscola.mutateAsync(formData);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <Container titulo={`${tituloForm} escola`} style={{ marginTop: "10%" }}>
      {!isEditar && (
        <form
          onSubmit={handleSubmitInep}
          style={{ padding: dataLoaded ? "0 0" : "0 10px 10px" }}
        >
          <Label>
            Digite o INEP:<span style={{ color: "red" }}>*</span>
          </Label>
          <FormGroup>
            <Input
              type="text"
              name="inep"
              value={formInep.inep}
              onChange={handleChangeInep}
              required
            />
            <ButtonWrapper>
              <CustomButton
                type="submit"
                style={{ marginTop: "0px", height: "38px" }}
              >
                <FaSearch />
              </CustomButton>
            </ButtonWrapper>
          </FormGroup>
        </form>
      )}
      {loading && (
        <div className="loading-popup">Buscando escola no SGE...</div>
      )}
      {dataLoaded && (
        <form onSubmit={handleSubmit} style={{ padding: "0 10px 10px" }}>
          <br />
          <br />
          <Label>
            Escola:
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Município:
            <Input
              type="text"
              name="municipio"
              value={formData.municipio}
              onChange={handleChange}
              required
            />
          </Label>
          <Label>
            Regional:
            <select
              id="regional"
              name="regional"
              onChange={handleChangeSelect}
              value={formData.regional || ""}
              required
              style={{
                fontSize: "1em",
                width: "100%",
                border: "1px solid transparent",
                borderRadius: "5px",
                backgroundColor: "#ebebeb",
                height: "35px",
                marginTop: ".2em",
              }}
            >
              <option value="">Selecione a regional</option>
              {selectOptions.length > 0 ? (
                selectOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Nenhuma opção disponível
                </option>
              )}
            </select>
          </Label>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="efi"
              checked={formData.efi}
              onChange={handleChangeCheckbox}
            />{" "}
            EFI - Ensino Fundamental I
          </LabelCheckbox>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="efii"
              checked={formData.efii}
              onChange={handleChangeCheckbox}
            />{" "}
            EFII - Ensino Fundamental II
          </LabelCheckbox>
          <LabelCheckbox>
            <input
              type="checkbox"
              name="em"
              checked={formData.em}
              onChange={handleChangeCheckbox}
            />{" "}
            EM - Ensino Médio
          </LabelCheckbox>
          <div
            style={{
              display: "flex",
              gap: "30px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <CustomButton type="submit">Salvar</CustomButton>
            <CustomButton variant="cancel" onClick={handleCancel}>
              Cancelar
            </CustomButton>
          </div>
        </form>
      )}
    </Container>
  );
};

export default EscolaForm;
