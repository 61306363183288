import React, { useState, useEffect } from "react";
import { MdOutlineHome } from "react-icons/md";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../Colors";

const StyledBreadCrumb = styled.div`
  height: 40px;
  background-color: ${colors.brancoPrimario};
  border-radius: 15px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  padding-left: 40px;
  transition: width 0.3s ease;

`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.preto};

  svg {
    margin-right: 5px;
  }
`;

const Crumb = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  

  a {
    text-decoration: none;
    color: ${colors.preto};
    text-transform: capitalize;
  }

  &:not(:last-child)::after {
    content: ">";
    margin-left: 10px;
  }
`;

const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbWidth, setBreadcrumbWidth] = useState("auto");
  const [crumbs, setCrumbs] = useState([]);

  for (let i = 0; i < crumbs.length; i++) {
    crumbs[i] = crumbs[i].replace("-", " ");
  }

  useEffect(() => {
    const calculateBreadcrumbWidth = () => {
      let totalWidth = 0;
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "16px Arial";

      const paddingContext = 62;
      const paddingHome = 15;

      crumbs.forEach((crumb, index) => {
        if (crumb.toLowerCase() === "home") {
          totalWidth += context.measureText("Início").width + paddingHome;
        } else {
          totalWidth += context.measureText(crumb).width + paddingContext;
        }
      });

      setBreadcrumbWidth(`${totalWidth}px`);
    };

    calculateBreadcrumbWidth();
  }, [crumbs]);

  useEffect(() => {
    const crumbsArray = location.pathname
      .split("/")
      .filter((crumb) => crumb !== "> ");
    setCrumbs(crumbsArray);
  }, [location]);

  return (
    <StyledBreadCrumb style={{ width: breadcrumbWidth }}>
      <HomeLink to="/home">
        <MdOutlineHome size={30} />
        Início
      </HomeLink>
      {crumbs.map((crumb, index) =>
        crumb === "home" ? null : (
          <Crumb key={index}>
            <Link to={`/${crumb}`}>{crumb}</Link>
          </Crumb>
        )
      )}
    </StyledBreadCrumb>
  );
};

export default Breadcrumb;
