import React from 'react'

// ícones
import { MdOutlinePendingActions, MdOutlineBlock } from "react-icons/md";
import { GoVerified } from "react-icons/go";
import { BsClipboard2Check } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { PiStudent } from 'react-icons/pi';
import { MdNoteAdd } from "react-icons/md";

//components
import CardGroup from "../../components/card/CardGroup";
import { formatNumberWithDot } from '../../utils/format';

const HomeSecretario = ({ totalAtivos, totalNovosAtivos, totalIndeferido, totalPendente, totalBolsistas }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  const itens = [
    {
      id: 0,
      title: "Ativos",
      variant: "green",
      type: "titleIconValue",
      value: formatNumberWithDot(totalAtivos),
      icon: GoVerified,
      buttonAction: () => {
        handleClick("/ativos");
      },
    },
    {
      id: 1,
      title: "Novos Homologados",
      variant: "oceanBlue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalNovosAtivos),
      icon: BsClipboard2Check,
      buttonAction: () => {
        handleClick("/novos-homologados");
      },
    },
    {
      id: 2,
      title: "Cadastrados",
      variant: "yellow",
      type: "titleIconValue",
      value: formatNumberWithDot(totalPendente),
      icon: MdOutlinePendingActions,
      buttonAction: () => {
        handleClick("/cadastrados");
      },
    },
    {
      id: 3,
      title: "Indeferidos",
      variant: "red",
      type: "titleIconValue",
      value: formatNumberWithDot(totalIndeferido),
      icon: MdOutlineBlock,
      buttonAction: () => {
        handleClick("/indeferidos");
      },
    },
    {
      id: 4,
      title: "Estudantes Geral",
      variant: "blue",
      type: "titleIconValue",
      value: formatNumberWithDot(totalBolsistas),
      icon: PiStudent,
      buttonAction: () => {
        handleClick("/estudantes");
      },
    },
    //{
    //  id: 5,
    //  title: "Cadastro de Estudante",
    //  variant: "blue",
    //  type: "titleIcon",
    //  icon: MdNoteAdd,
    //  buttonAction: () => {
    //    handleClick("/estudantes/cadastro");
    //  },
   //},
  ];
  return (
    <CardGroup menuItems={itens}/>
  )
}

export default HomeSecretario
